import * as React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import Seo from "../components/seo";
import Layout from "../components/layout";

import "../styles/global/base.scss";
import "../styles/not-found.scss";

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <Seo title="Not Found" pageClass="notFoundPage" />
      <Layout>
        <Container fluid className="notfoundHeader">
          <Row>
            <Col>
              <h1>404 Not Found</h1>
              <div>
                <p>ページが見つかりませんでした。<br />お探しのページは移動もしくは削除された可能性があります。</p>
              </div>
              <Link to="/">トップページへ</Link>
            </Col>
          </Row>
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export default NotFoundPage;
